import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthContextService } from '../services/auth-context.service';

@Directive({
  selector: '[xPermissiveIf]',
})
export class PermissiveIfDirective implements OnDestroy {
  private _destroy$ = new Subject<void>();

  @Input() set xPermissiveIf(permissions: string | string[]) {
    if (!Array.isArray(permissions)) permissions = [permissions];

    this.authContext
      .userHasPermissions(permissions)
      .pipe(
        tap((allowed) => {
          if (allowed) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.cd.markForCheck();
          } else {
            this.viewContainer.clear();
            this.cd.markForCheck();
          }
        }),
        takeUntil(this._destroy$),
      )
      .subscribe();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
    private authContext: AuthContextService,
  ) {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
