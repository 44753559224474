import { ModuleWithProviders, NgModule } from '@angular/core';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { CurrentUserComponent } from './components/current-user/current-user.component';
import { PermissiveIfDirective } from './directives/permissive.directive';
import { AuthGuard } from './guards/auth.guard';
import { AuthContextService } from './services/auth-context.service';
import { DataViewManagerService } from './services/data-view-manager.service';
import { VariableContextService } from './services/variable-context.service';

@NgModule({
  imports: [EcommerceAdminCoreModule],
  declarations: [PermissiveIfDirective, CurrentUserComponent, AuthLayoutComponent],
  exports: [PermissiveIfDirective, CurrentUserComponent, AuthLayoutComponent],
})
export class AuthSharedModule {
  static forRoot(): ModuleWithProviders<AuthSharedModule> {
    return {
      ngModule: AuthSharedModule,
      providers: [AuthContextService, AuthGuard, VariableContextService, DataViewManagerService],
    };
  }
}
